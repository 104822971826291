.main__container--scoreboard .score {
  font-size: 108px;
}

.main__container--scoreboard .score--small {
  font-size: 72px;
}

.main__container--scoreboard .timer {
  font-size: 72px;
}

.main__container--scoreboard .period {
  font-size: 36px;
}
