/* Layout */
.scoreboard {
  display: flex;
  height: 100%;
  width: 100%;
}

.scoreboard__column {
  padding: 3% 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.scoreboard__column--team {
  width: 30%;
}

.team {
  height: 45%;
  width: 80%;
}

.scoreboard__column--main {
  width: 40%;
}

.score {
  height: 45%;
  width: 90%;
}

.timer {
  height: 30%;
  width: 90%;
  margin: 5% 0;
}

.period {
  height: 15%;
  width: 30%;
}

/* Fonts */

@font-face {
  font-family: Chopsin;
  src: url(font/Chopsin-X3YPo.ttf);
}

@font-face {
  font-family: lcd;
  src: url(font/LCD14.otf);
}

.scoreboard {
  font-family: Chopsin;
  font-family: lcd;
}

/* Rest */

.scoreboard {
  background-size: cover;
}

.box {
  color: white;
  background-color: #2f3032;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score {
  font-size: min(30vh, 15vw);
}
.score--small {
  font-size: min(25vh, 10vw);
}

.timer {
  color: #90ff03;
  font-size: min(20vh, 8vw);
}

.period {
  font-size: min(10vh, 5vw);
}

.team {
  display: flex;
  flex-direction: column;
}

.logo {
  max-width: 90%;
  max-height: 90%;
}
